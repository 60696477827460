
    import axios from 'axios';
    import { mapActions, mapState } from 'vuex';
    import IWButton from '@components/IWButton.vue';
    import api from '../api';
    import BaseModal from './BaseModal';

    export default {
        name: 'DropGameScoreboardModal',

        components: {
            IWButton,
        },

        extends: BaseModal,

        data () {
            return {
                score: 0,
                maxScore: 0,
                hasPlayedInstantWinGame: false,
            };
        },

        computed: {
            ...mapState(['profile']),
        },

        i18nOptions: { namespaces: ['drop_game'] },

        async mounted () {
            const result = await axios.get(`${api.base}/game/score`, {});
            this.score = result.data.score;
            this.maxScore = result.data.max_score;
            this.hasPlayedInstantWinGameBefore();
        },

        methods: {
            ...mapActions('app', {
                isActionLimited: 'isActionLimited',
            }),

            async hasPlayedInstantWinGameBefore () {
                const response = await this.isActionLimited({ action: 'generateToken' });
                this.hasPlayedInstantWinGame = response.data.hasPlayedBefore;
            },

        },
    };
