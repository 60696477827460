
    import { mapState } from 'vuex';
    import { openPopup } from '@public/js/popup';
    import openModal, { PrizeDetailsModal } from '../modals';
    export default {
        i18nOptions: { namespaces: ['footer', 'links', 'global'] },
        computed: {
            ...mapState(['app']),
        },
        methods: {
            openPrizeDetailsModal () {
                openModal(PrizeDetailsModal);
            },
            onPopup (e) {
                this.onSelected();
                openPopup(e);
            },
            onSelected () {
                this.$emit('selected');
            },
        },
    };
