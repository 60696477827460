
    import BaseModal from './BaseModal';
    export default {
        name: 'MemoryMatchGameModal',
        extends: BaseModal,
        i18nOptions: { namespaces: ['memory_match_game'] },
        props: {
            helpClick: {
                type: Boolean,
                default: false,
            },
        },
    };
