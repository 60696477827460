
// Asynchronous component definitions for lazy-loading routes.
// https://v3.router.vuejs.org/guide/advanced/lazy-loading.html
// https://webpack.js.org/guides/code-splitting/#dynamic-imports
// https://webpack.js.org/api/module-methods/#magic-comments
const
    DropGamePage = async () => import(
        /* webpackChunkName: 'game' */
        '../views/DropGamePage.vue'
    ),
    MemoryGamePage = async () => import(
        /* webpackChunkName: 'memory' */
        '../views/MemoryGamePage.vue'
    ),
    PlayAgainPage = async () => import(
        /* webpackChunkName: 'playagain' */
        '../views/PlayAgainPage.vue'
    ),
    InkyPage = async () => import(
        /* webpackChunkName: 'game' */
        '../views/InkyPage.vue'
    ),
    JankyPage = async () => import(
        /* webpackChunkName: 'game' */
        '../views/JankyPage.vue'
    );

export default [
    { path: '/game/drop',  component: DropGamePage },
    { path: '/game/memory',  component: MemoryGamePage },
    { path: '/game/inky',  component: InkyPage },
    { path: '/game/janky', component: JankyPage },
    { path: '/game/play-again', component: PlayAgainPage },
    { path: '/game/*', redirect: (to) => '/hub' },
];
