
    import { mapState } from 'vuex';
    import BaseModal from './BaseModal';

    export default {
        name: 'PrizeDetailsModal',
        extends: BaseModal,
        computed: {
            ...mapState(['profile']),
            rulesLink () {
                return `<a href="${this.app.tb2URL}public/fulfillment/rules.pdf" target="_blank">${this.$t('links:rules_copy')}</a>`;
            },
        },
        i18nOptions: { namespaces: ['prize_details'] },
    };
