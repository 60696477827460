
    import SiteLinks from './SiteLinks.vue';
    import { mapState } from 'vuex';
    export default {
        components: {
            SiteLinks,
        },
        data () {
            return {
                open: false,
            };
        },
        computed: {
            ...mapState(['ui']),
        },
    };
