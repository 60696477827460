
    import { mapState, mapGetters, mapActions } from 'vuex';
    import { openPopup } from '../popup';
    import SiteLinks from './SiteLinks.vue';

    export default {
        name: 'TheFooter',
        components: {
            SiteLinks,
        },

        i18nOptions: { namespaces: ['footer', 'links', 'global'] },

        computed: {
            ...mapState([
                'app',
                'profile',
            ]),
            ...mapGetters({
                loggedIn: 'profile/loggedIn',
            }),
            sponsors_disclaimer () {
                if (this.app.phase != 'launched') {
                    return `${this.$t('footer:sponsors_disclaimer')}`;
                }
                else {
                    return '';
                }
            },
            rulesLink () {
                return `<a href="${this.app.tb2URL}public/fulfillment/rules.pdf" target="_blank">${this.$t('links:rules_copy')}</a>`;
            },
        },

        methods: {
            openPopup,

            ...mapActions({
                logOut: 'profile/logOut',
            }),
        },
    };

