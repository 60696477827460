
    import BasePage from './BasePage';

    export default {
        name: 'ErrorPage',

        extends: BasePage,

        data () {
            return {
                pageName: 'error',
            };
        },
        mounted () {
            this.$webAnalytics.track('view_screen', { category: 'Lifecycle', label: 'Error Page' });
        },
        i18nOptions: { namespaces: ['error'] },
    };
