
import axios from 'axios';
import store from './store/index';
import api from './api';
import openModal, { ErrorModal } from './modals';
import { setRecaptchaV3Challenge } from './recaptcha';

const game = {
    async play () {
        try {
            const data = {
                id: store.state.profile.id,
            };

            await setRecaptchaV3Challenge({ data, action: 'play' });
            const response = await axios.post(`${api.base}/game/play`, data);

            const {
                prizeWon = false,
                awards: {
                    isLimited = true,
                    tokenCount: playsRemaining = 0,
                },
            } = response.data;

            // TODO: feels like most of this should be refactored into a 'game' Vuex store.
            store.commit('profile/updateProfile', {
                is_limited: isLimited,
                plays_remaining: playsRemaining,
                prize_won: prizeWon,
            });

            return prizeWon;
        }
        catch (err) {
            openModal(ErrorModal);
            throw err;
        }
    },
};

export default game;
