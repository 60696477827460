
    import { mapActions } from 'vuex';
    import game from '@public/js/game';

    export default {
        i18nOptions: { namespaces: ['buttons'] },

        data () {
            return {
                prizeWon: undefined,
                hasPlayedInstantWinGame: false,
            };
        },

        mounted () {
            this.hasPlayedInstantWinGameBefore();
        },

        methods: {
            ...mapActions('app', {
                isActionLimited: 'isActionLimited',
            }),
            ...mapActions('game', ['generateToken']),

            async hasPlayedInstantWinGameBefore () {
                const response = await this.isActionLimited({ action: 'generateToken' });
                this.hasPlayedInstantWinGame = response.data.hasPlayedBefore;
            },

            async isWinnerClick () {
                await this.generateToken();
                this.prizeWon = await game.play();
                this.navigateToGameResult();
                this.$parent.closeModal();
            },

            async backToHubClick () {
                this.$parent.closeModal();
                this.$router.push('/hub');
            },

            navigateToGameResult () {
                const { prizeWon } = this;
                if (prizeWon) {
                    return this.$router.replace({ path: '/game/inky', params: { prizeWon } });
                }
                else {
                    return this.$router.replace({ path: '/game/janky' });
                }
            },
        },
    };
