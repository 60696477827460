
    import { mapState } from 'vuex';
    import BaseModal from './BaseModal';

    export default {
        name: 'MemoryMatchWinModal',
        extends: BaseModal,
        computed: {
            ...mapState(['game']),
        },
        i18nOptions: { namespaces: ['memory_match_game'] },
        methods: {
            backToHubClick () {
                this.closeModal();
            },

        },
    };
