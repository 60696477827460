import axios from 'axios';
import { reactive, set } from 'vue';

import api from '../../api';

const getDefaultState = () => reactive({
    advergamePlayedToday: true,
    memoryMatchPlayedToday: true,
});

const state = getDefaultState();

const getters = {
};

const mutations = {
    updateGameStoreState (state, data) {
        for (const key in data) {
            set(state, key, data[key]);
        }
    },
};

const actions = {
    async generateToken ({ commit }) {
        try {
            const response = await axios.post(`${api.base}/game/award/generateToken`);
            return response;
        }
        catch (err) {
            if (err.response.data.awards.isLimited) {
                return;
            }
            console.error(
                'error generating token',
                err.message,
                err,
            );
            throw err;
        }
    },
    async getButtonLimits ({ commit }) {
        try {
            const response = await axios.get(`${api.base}/game/play-limited`);

            commit('updateGameStoreState', {
                advergamePlayedToday: response.data.advergameLimited,
                memoryMatchPlayedToday: response.data.memoryMatchLimited,
            });
            return response;
        }
        catch (err) {
            if (err.response.data.awards.isLimited) {
                return;
            }
            console.error(
                'error generating token',
                err.message,
                err,
            );
            throw err;
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
