/* eslint-disable eslint-comments/no-unlimited-disable */
/* eslint-disable */
let settings = {};
const lyticsID = process.env.NODE_ENV === 'production' ? 'b965e5cf4614b63a47055bd17650da09' : 'c09e6698e3765827a4dab8965f2a8018';

export default {
    install (Vue, options) {
        settings = { ...options };
        pageViewSnippet();
    },
};

export function lyticsPageView () {
    jstag.pageView();
}

export function lyticsTrackReg (userEmail) {
    jstag.send({
        _e: settings.promoName,
        email: userEmail,
        promo_profile_id: settings.promoProfileID,
    });
}

function pageViewSnippet () {
    !function(){"use strict";var o=window.jstag||(window.jstag={}),r=[];function n(e){o[e]=function(){for(var n=arguments.length,t=new Array(n),i=0;i<n;i++)t[i]=arguments[i];r.push([e,t])}}n("send"),n("mock"),n("identify"),n("pageView"),n("unblock"),n("getid"),n("setid"),n("loadEntity"),n("getEntity"),n("on"),n("once"),n("call"),o.loadScript=function(n,t,i){var e=document.createElement("script");e.async=!0,e.src=n,e.onload=t,e.onerror=i;var o=document.getElementsByTagName("script")[0],r=o&&o.parentNode||document.head||document.body,c=o||r.lastChild;
    return null!=c?r.insertBefore(e,c):r.appendChild(e),this},o.init=function n(t){return this.config=t,this.loadScript(t.src,function(){if(o.init===n)throw newError("Load error!");o.init(o.config),function(){for(var n=0;n<r.length;n++){var t=r[n][0],i=r[n][1];o[t].apply(o,i)}r=void 0}()}),this}}();

    jstag.init({ src: `//c.lytics.io/api/tag/${lyticsID}/latest.min.js` });
}
/* eslint-enable */
